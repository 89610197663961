import React from "react";
import {
  FOOTER_AGENT,
  FOOTER_DATE,
  FOOTER_ENTERPRISE,
  FOOTER_SOCIAL,
  FOOTER_SUBTITLE,
  FOOTER_TITLE,
  FOOTER_WEBSITE,
  FOOTER_CONDITION,
  FOOTER_MENTIONS,
  FOOTER_PREFERENCES,
  FOOTER_NUMMBER,
  FOOTER_EMAIL,
} from "../../constants/data";
import "./Footer.scss";
import Ipi from "../../assets/images/Logo IPI_BIV white.png";
import FaceBook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/instagram.svg";
import Linkedin from "../../assets/images/linkedin.svg";
import pdf from "../../assets/pdf/Conditions générales EQUIMAS landing page et site web.pdf";
import pdf_2 from "../../assets/pdf/Mentions légales EQUIMAS landing page et site web.pdf";
import pdf_3 from "../../assets/pdf/Préférences pour les cookies EQUIMAS landing page et site web.pdf";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-left">
        <h2>{FOOTER_TITLE}</h2>
        <div>{FOOTER_SUBTITLE}</div>
      </div>
      <div className="footer-right">
        <div className="row">
          <h4>{FOOTER_ENTERPRISE}</h4>
          <div className="col">
            <div>
              <h4>{FOOTER_NUMMBER}</h4>
              <a
                href="mailto:info@equimas.be"
                style={{ textDecoration: "none" }}
              >
                <h4>{FOOTER_EMAIL}</h4>
              </a>
            </div>
            <h4>{FOOTER_SOCIAL}</h4>
            <div className="footer-social">
              <a
                href="https://www.facebook.com/EQUIMASBE/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={FaceBook} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/equimas-be/?viewAsMember=true"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={Linkedin} alt="" />
              </a>
              <a
                href="https://www.instagram.com/equimas_real_estate/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Instagram} alt="" />
              </a>
            </div>
          </div>
          <img src={Ipi} alt="" />
        </div>
        <h4 className="row-item">
          {FOOTER_AGENT} -{" "}
          <span>
            <a href="https://www.ipi.be/">{FOOTER_WEBSITE}</a>
          </span> - Cf.{" "}
          <span>
            <a href="https://www.ejustice.just.fgov.be/cgi_loi/change_lg.pl?language=fr&la=F&table_name=loi&cn=2006092738">
              {FOOTER_DATE}
            </a>
          </span>
        </h4>
        <div className="subrow">
          <a href={pdf} target="blank" rel="noopener noreferrer">
            <h4>{FOOTER_CONDITION}</h4>
          </a>
          <a href={pdf_2} rel="noopener noreferrer" target="_blank">
            <h4>{FOOTER_MENTIONS}</h4>
          </a>
          <a href={pdf_3} rel="noopener noreferrer" target="_blank">
            <h4>{FOOTER_PREFERENCES}</h4>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
