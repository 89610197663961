import React from "react";
import { SERVICE_ACCOMPAGNEMENT, SERVICE_BIENS, SERVICE_PRIX } from "../../constants/data";
import Accompagnement from "../../assets/images/accompagnement.svg";
import Gamme from "../../assets/images/gamme.svg";
import Prix from "../../assets/images/prix.svg";
import "./Service.scss";

const Service = () => {
  return <div className="service-container">
    <div className="service-item">
      <img src={Gamme} alt="" />
      <h3>{SERVICE_BIENS}</h3>
    </div>
    <div className="service-item"><img src={Accompagnement} alt="" />
      <h3>{SERVICE_ACCOMPAGNEMENT}</h3></div>
    <div className="service-item"><img src={Prix} alt="" />
      <h3>{SERVICE_PRIX}</h3></div>
  </div>;
};

export default Service;
