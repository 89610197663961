import React from "react";
import { RATING_TITLE, RATING_ARRAY } from "../../constants/data";
import "./Rating.scss";
import CheckMark from "../../assets/images/check.svg";
import Rating1 from "../../assets/images/rating1.svg";
import Rating2 from "../../assets/images/rating2.svg";
import Rating3 from "../../assets/images/rating3.svg";
import Google from "../../assets/images/google.svg";

const Rating = () => {
  const images = [CheckMark, Rating1, Rating2, Rating3, Google];
  const items = images.map((el, key) => (
    <div key={key} className="rating-item">
      <img src={el} alt="" />
      <span>{RATING_ARRAY[key]}</span>
    </div>
  ));
  return (
    <div className="rating-container">
      <h2>{RATING_TITLE}</h2>
      <hr />
      <div className="rating-row">{items}</div>
    </div>
  );
};

export default Rating;
