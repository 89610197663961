export const HERO_TITLE = "Des biens de qualité proches de chez vous";
export const HERO_SUBTITLE =
  "EQUIMAS vous accompagne dans vos projets immobiliers à Bruxelles et dans le Brabant-Wallon.";
export const HERO_SERVICES =
  "PROJETS NEUFS - VENTE - \n LOCATION - GESTION - ESTIMATION";
export const HERO_DESCRIPTION =
  "Nous sommes en train de préparer notre nouveau site. \n Pour être notifié dès la mise-en-ligne, \n indiquez nous votre adresse ici :";
export const HERO_PLACEHOLDER = "Adresse email";
export const HERO_BUTTON = "S’inscrire";

export const SERVICE_BIENS = "Des biens haut de gamme";
export const SERVICE_ACCOMPAGNEMENT = "Un accompagnement sur-\u2060mesure";
export const SERVICE_PRIX = "Des prix justes";

export const SALES_TITLE = "Nos dernières ventes";
export const SALES_DESCRIPTION =
  "Que vous souhaitiez vendre, louer ou acheter un bien, EQUIMAS est à votre écoute.";

export const MOCKUP_TITLE =
  "EQUIMAS fait appel aux technologies les plus modernes pour vous aider à trouver le bien idéal et à le vendre au meilleur prix.";
export const MOCKUP_SUBTITLE =
  "Toutes nos offres vous sont facilement accessibles avec filtres, alertes, etc.";

export const ADVANTAGES_TITLE = "Nos atouts";
export const ADVANTAGES_ARRAY = [
  "Vidéo de présentation du bien",
  "Visite Virtuelle 3D Matterport",
  "Home Staging Virtuel",
  "Drone Vidéo",
  "Photos 4K HDR",
  "Plans",
  "Brochures",
  "Rapport d’Estimation",
  "Sites d’Annonces Variés",
];

export const OFFERT_SUBTITLE =
  "*Estimation réalisée d’après les transactions des 3 dernières années. Estimation offerte gratuitement et sans engagement. ";
export const OFFERT_BUTTON = "OBTENEZ VOTRE ESTIMATION GRATUITE";
export const OFFERT_ELLIPCE = "Bon";
export const OFFERT_VENDU = "14,5%";

export const RATING_TITLE = "L’assurance d’un service premium";
export const RATING_ARRAY = [
  "25 ans\n d’expérience",
  "Taux de satisfation client",
  "Taux de vente\n réussie",
  "Temps de vente\n moyen",
  "4,8/5\n Avis google",
];

export const ABOUT_TITLE = "Ils parlent de nous";
export const ABOUT_INFO =
  "Grâce à Macià Eduard Wilhelm de l'agence Equimas, j'ai trouvé l'appartement dans lequel nous vivons très heureux depuis presque 3 ans. Avant la visite, nous avons bu un sympathique café, il nous a raconté la vie du quartier, donné quelques bons plans, c'était très agréable de ne pas être que dans un rapport de client. Ensuite, il a toujours facilité les choses entre le propriétaire et nous, pour la paperasse, l'état des lieux etc...Excellente expérience, donc! Je recommande vivement!";
export const ABOUT_PERSON = "Cécile Maistre-Chabrol";
export const ABOUT_JOB = "Réalisatrice, Scénariste, Actrice et Ecrivaine";

export const FLYER_TITLE = "Contactez-nous";
export const FLYER_SUBTITLE =
  "Vous souhaitez vendre un bien à travers notre agence ou vous souhaitez plus d’informations? Écrivez-nous! Nous vous répondons le jour-même.";

export const FOOTER_TITLE = "EQUIMAS";
export const FOOTER_SUBTITLE = "Des biens de qualité\n proches de chez vous.";
export const FOOTER_ENTERPRISE =
  "Bureau Immobiler - EQUIMAS SRL\n N° entreprise : BE0 891 807 805 - rpm Bruxelles\n Sis à 1050 Ixelles, Rue de l’Arbre Bénit 57\n AXA Belgium SA, Police d'Assurance 730.390.160\n 'Responsabilité Civile Professionnelle et Cautionnement'";
export const FOOTER_NUMMBER = "+32 472 43 56 80";
export const FOOTER_EMAIL = "info@equimas.be";
export const FOOTER_AGENT =
  "Agent Immobilier agréé IPI n°503 532 \nSoumis au code déontologique de l’ IPI:";
export const FOOTER_WEBSITE = "www.ipi.be";
export const FOOTER_DATE = "Arrêté royal du 27 novembre 2006";
export const FOOTER_SOCIAL = "Suivez-nous";
export const FOOTER_CONDITION = "Conditions générales d’utilisation";
export const FOOTER_MENTIONS = "Mentions légales";
export const FOOTER_PREFERENCES = "Préférences pour les cookies";
