import React from "react";
import "./Offert.scss";
import Laptop from "../../assets/images/laptop.png";
import Pexels from "../../assets/images/pexels.svg";
import {
  OFFERT_BUTTON,
  OFFERT_ELLIPCE,
  OFFERT_SUBTITLE,
} from "../../constants/data";

const Offert = () => {
  return (
    <div className="offert-container">
      <div className="offert-row">
        <div className="offert-column">
          <h2>
            EQUIMAS vous offre un rapport d’estimation précis et complet d’une
            valeur de <span>450€ Htva de 21%,</span> basé sur l’analyse par
            algorithme de la base de données
            <span> la plus complète du marché.</span>*
          </h2>
          <h4>{OFFERT_SUBTITLE}</h4>
        </div>
        <div className="offert-laptop">
          <img src={Laptop} alt="" />
          <div className="offert-ellipce">
            {OFFERT_ELLIPCE}
            <span>cadeau</span>
          </div>
        </div>
      </div>
      <div className="offert-btn">{OFFERT_BUTTON}</div>
      <div className="offert-row">
        <div className="offert-image">
          <img src={Pexels} alt="" />
          <div className="offert-vendu">
            <span>Vendu</span>
            <span>prix</span>
          </div>
        </div>
        <div className="offert-col">
          <span>“</span>
          <div>
            Dans notre exemple, le studio a été vendu à 295.000€, soit dans le
            HAUT de notre fourchette d'estimation. Le propriétaire est satisfait
            d'avoir choisi l'agence EQUIMAS, d'autant que le bien était estimé
            par une autre agence à 255.000€. Nous avons donc
            <span> vendu à un prix supérieur de 14.5%.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offert;
