import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import toast, { Toaster } from "react-hot-toast";
import { FLYER_TITLE, FLYER_SUBTITLE } from "../../constants/data";
import FlyerImage from "../../assets/images/flyer.png";
import "./Flyer.scss";

const Flyer = () => {
  const [user_name, setUserName] = useState("");
  const [user_surname, setUserSurname] = useState("");
  const [user_email, setUserEmail] = useState("");
  const [user_phone, setUserPhone] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();

  const notify = () =>
    toast.success("The email has been sent", {
      position: "bottom-center",
      style: {
        border: "1px solid #00876c",
        padding: "16px",
        color: "#00876c",
      },
      iconTheme: {
        primary: "#00876c",
        secondary: "#FFFAEE",
      },
    });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_gduxmtb",
        "template_uoxfud9",
        form.current,
        "Za-rWkIXv4tOzrRfj"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setUserName("");
    setUserEmail("");
    setUserPhone("");
    setMessage("");
    setUserSurname("");

    notify();
  };

  return (
    <div className="flyer-container">
      <div className="flyer-contact">
        <h2>{FLYER_TITLE}</h2>
        <h4>{FLYER_SUBTITLE}</h4>
        <form action="" className="flyer-form" ref={form} onSubmit={sendEmail}>
          <div className="row">
            <input
              className="flyer-input"
              placeholder="Nom"
              type="text"
              name="user_name"
              value={user_name}
              required
              onChange={(event) => setUserName(event.target.value)}
            />
            <input
              className="flyer-input"
              placeholder="Prénom"
              type="text"
              name="user_name"
              value={user_surname}
              required
              onChange={(event) => setUserSurname(event.target.value)}
            />
          </div>
          <div className="row">
            <input
              className="flyer-input"
              placeholder="Adresse email"
              type="email"
              name="user_email"
              value={user_email}
              required
              onChange={(event) => setUserEmail(event.target.value)}
            />
            <input
              className="flyer-input"
              placeholder="Téléphone"
              type="number"
              name="user_phone"
              value={user_phone}
              required
              onChange={(event) => setUserPhone(event.target.value)}
            />
          </div>
          <textarea
            placeholder="Votre message"
            name="message"
            value={message}
            required
            onChange={(event) => setMessage(event.target.value)}
          />
          <button type="submit">ENVOYER</button>
        </form>
        <Toaster />
      </div>
      <div className="flyer">
        <img src={FlyerImage} alt="" />
      </div>
    </div>
  );
};

export default Flyer;
