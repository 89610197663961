import React from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import { Navigation } from "swiper";
import Sales1 from "../../assets/images/sales-1.png";
import Sales2 from "../../assets/images/sales-2.png";
import Sales3 from "../../assets/images/sales-3.png";
import Sales4 from "../../assets/images/sales-4.png";
import Sales5 from "../../assets/images/sales-5.png";
import { SALES_DESCRIPTION, SALES_TITLE } from "../../constants/data";
import "./Sales.scss";

const Sales = () => {
  const items = [Sales1, Sales2, Sales3, Sales4, Sales5].map((item, key) => (
    <SwiperSlide key={key}>
      <img src={item} alt="" />
    </SwiperSlide>
  ));

  return (
    <div className="sales-container">
      <div className="sales-info">
        <h2>{SALES_TITLE}</h2>
        <hr />
        <h4>{SALES_DESCRIPTION}</h4>
      </div>
      <div className="sales-swiper">
        <Swiper
          spaceBetween={40}
          loop={true}
          navigation={true}
          slidesPerView={1}
          modules={[Navigation]}
        >
          {items}
        </Swiper>
      </div>
    </div>
  );
};

export default Sales;
