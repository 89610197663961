import React from "react";
import { ADVANTAGES_ARRAY, ADVANTAGES_TITLE } from "../../constants/data";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "./Advantages.scss";

import ArrowLeft from "../../assets/images/arrow-left.svg";
import ArrowRight from "../../assets/images/arrow-right.svg";
import Presentation from "../../assets/images/video-presentation.png";
import Vr from "../../assets/images/vr.png";
import HomeStaging from "../../assets/images/home-staging.png";
import Drone from "../../assets/images/drone-03.png";
import Photos4k from "../../assets/images/4K-04.png";
import Plans from "../../assets/images/plan-05.png";
import Brochure from "../../assets/images/brochure.png";
import Rapport from "../../assets/images/rapport.png";

const Advantages = () => {
  const images = [
    Presentation,
    Vr,
    HomeStaging,
    Drone,
    Photos4k,
    Plans,
    Brochure,
    Rapport,
  ];
  const items = images.map((el, key) => (
    <SwiperSlide key={key}>
      <div className="advantages-box">
        <img src={el} alt="" />
        <span>{ADVANTAGES_ARRAY[key]}</span>
      </div>
    </SwiperSlide>
  ));

  return (
    <div className="advantages-container">
      <h2>{ADVANTAGES_TITLE}</h2>
      <hr />
      <div className="advantages-swiper">
        <img className="swiper-button-left" alt="" src={ArrowLeft} />
        <Swiper
          spaceBetween={36}
          loop={true}
          navigation={{
            nextEl: ".swiper-button-left",
            prevEl: ".swiper-button-right",
          }}
          slidesPerView={4}
          modules={[Navigation]}
        >
          {items}
        </Swiper>
        <img className="swiper-button-right" alt="" src={ArrowRight} />
      </div>
    </div>
  );
};

export default Advantages;
