import React from "react";
import "./About.scss";
import Merged from "../../assets/images/merged.svg";
import Person from "../../assets/images/person.svg";
import {
  ABOUT_INFO,
  ABOUT_JOB,
  ABOUT_PERSON,
  ABOUT_TITLE,
} from "../../constants/data";

const About = () => {
  return (
    <div className="about-container">
      <div className="about-left">
        <h2>{ABOUT_TITLE}</h2>
        <hr />
      </div>
      <div className="about-right">
        <img src={Merged} alt="" />
        <span>“</span>
        <div>{ABOUT_INFO}</div>
        <div className="about-person">
          <div className="about-col">
            <div>{ABOUT_PERSON}</div>
            <span>{ABOUT_JOB}</span>
          </div>
          <img src={Person} alt="" />
        </div>
      </div>
    </div>
  );
};

export default About;
