import React from "react";
import {
  HERO_BUTTON,
  HERO_DESCRIPTION,
  HERO_PLACEHOLDER,
  HERO_SUBTITLE,
  HERO_TITLE,
  HERO_SERVICES
} from "../../constants/data";
import "./Hero.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";

const Hero = () => {
  return (
    <div className="hero-container">
      <div className="hero-info">
        <div className="hero-title">
          <h1>{HERO_TITLE}</h1>
          <h3>{HERO_SUBTITLE}</h3>
        </div>
        <div className="hero-subscribe">
          <h3 className="hero-services">{HERO_SERVICES}</h3>
          <h4>{HERO_DESCRIPTION}</h4>
          <div className="hero-input">
            <input type="email" placeholder={HERO_PLACEHOLDER} />
            <button onClick={(e) => e.preventDefault()}>{HERO_BUTTON}</button>
          </div>
        </div>
      </div>
      <div className="hero-slider">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          navigation={true}
          modules={[Navigation]}
        >
          <SwiperSlide>
            <div className="hero hero-first" />
          </SwiperSlide>
          <SwiperSlide>
            <div className="hero hero-second" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Hero;
