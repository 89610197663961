import About from "../About/About";
import Advantages from "../Advantages/Advantages";
import Flyer from "../Flyer/Flyer";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Hero from "../Hero/Hero";
import Mockup from "../Mockup/Mockup";
import Offert from "../Offert/Offert";
import Rating from "../Rating/Rating";
import Sales from "../Sales/Sales";
import Service from "../Services/Service";
import "./App.scss";

const App = () => {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Service />
      <Sales />
      <Mockup />
      <Advantages />
      <Offert />
      <Rating />
      <About />
      <Flyer />
      <Footer />
    </div>
  );
};

export default App;
