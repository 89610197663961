import React from "react";
import "./Header.scss";

const Header = () => {
  return (
    <div className="header">
      <div />
      <div />
    </div>
  );
};

export default Header;
