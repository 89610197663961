import React from "react";
import "./Mockup.scss";
import MockupWebsite from "../../assets/images/mockup.png";
import { MOCKUP_SUBTITLE, MOCKUP_TITLE } from "../../constants/data";

const Mockup = () => {
  return (
    <div className="mockup-container">
      <img src={MockupWebsite} alt="" />
      <div className="mockup-col">
        <div className="mockup-title">{MOCKUP_TITLE}</div>
        <div className="mockup-subtitle">{MOCKUP_SUBTITLE}</div>
      </div>
    </div>
  );
};

export default Mockup;
